import React, { useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useTrail, useChain, useSpring, animated, config } from "react-spring"

const Nav = ({ menuState, closeNav }) => {
  const navItems = useStaticQuery(graphql`
    query {
      prismicNav {
        data {
          main_navigation {
            nav_title {
              text
            }
            page {
              uid
            }
          }
        }
      }
    }
  `)
  const { main_navigation } = navItems.prismicNav.data
  // Spring
  const springRef = useRef()
  const { x } = useSpring({
    ref: springRef,
    from: {
      x: "0",
    },
    to: {
      x: menuState ? "1" : "0",
    },
  })
  // Trail
  const trailRef = useRef()
  const trail = useTrail(main_navigation.length, {
    ref: trailRef,
    config: config.stiff,
    opacity: menuState ? 1 : 0,
    x: menuState ? 0 : 20,
    from: { opacity: 0, x: 20 },
  })
  useChain(menuState ? [springRef, trailRef] : [trailRef, springRef])

  return (
    <>
      <animated.div
        className="fixed w-100 h-100 top-0 left-0 doc-bg flex items-center z-999"
        style={{
          opacity: x.interpolate(x => x),
          pointerEvents: menuState ? "all" : "none",
        }}
      >
        <ul className="list flex-l justify-between items-center w-100 ph4 ph5-l mwnav center tr">
          {trail.map(({ x, ...rest }, index) => (
            <animated.li
              key={main_navigation[index]}
              style={{
                ...rest,
                transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
              }}
              className="mv4 mv0-l"
            >
              <Link
                to={`/${main_navigation[index].page.uid}`}
                className="link black b f3 ttu tracked db"
                onClick={closeNav}
              >
                {main_navigation[index].nav_title.text}
              </Link>
            </animated.li>
          ))}
        </ul>
      </animated.div>
    </>
  )
}
export default Nav
