import React from "react"
import { useSpring, animated, config } from "react-spring"

const BraveBurger = ({ menuState, reverseHeader }) => {
  const { x, color } = useSpring({
    x: menuState ? 1 : 0,
    color: menuState ? "#000000" : reverseHeader ? "#ffffff" : "#000000",
    config: config.stiff,
  })
  return (
    <animated.svg
      viewBox="0 0 96 96"
      height="1em"
      style={{
        overflow: "visible",
        cursor: "pointer",
        position: "relative",
        fontSize: "2em",
        color: color,
      }}
    >
      <g
        id="navicon"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="square"
        strokeLinejoin="square"
      >
        <animated.line
          x1="7"
          y1="26"
          x2="89"
          y2="26"
          transform={x.interpolate(
            x => `translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`
          )}
        />
        <animated.line
          x1="7"
          y1="70"
          x2="89"
          y2="70"
          transform={x.interpolate(
            x => `translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`
          )}
        />
      </g>
    </animated.svg>
  )
}

export default BraveBurger
