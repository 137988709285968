import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "../../svg/logo.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicNav {
        data {
          footer_nav {
            page {
              uid
            }
            nav_title {
              text
            }
          }
        }
      }
    }
  `)
  const { footer_nav } = data.prismicNav.data
  return (
    <footer className="flex-l items-center justify-between ph4 ph5-l white footer-bg pv4 pv5-l mt5 mt0-l">
      <Link className="w5 db center ml0-l" to="/">
        <Logo className="fill-white" />
      </Link>
      <div className="flex justify-center mt4">
        {footer_nav.map(item => (
          <Link
            to={`/${item.page.uid}`}
            className="link white tracked ttu mh2 ml4-l mr0-l"
          >
            {item.nav_title.text}
          </Link>
        ))}
      </div>
    </footer>
  )
}

export default Footer
