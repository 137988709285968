/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from "react-spring"

import Header from "./nav/header"
import Footer from "./nav/footer"

import "tachyons"
import "../css/main.css"

const Layout = ({ children, reverseHeader = false }) => {
  const { x } = useSpring({
    from: {
      x: 0,
    },
    to: {
      x: 1,
    },
  })
  return (
    <>
      <Header reverseHeader={reverseHeader} />
      <animated.main
        style={{
          opacity: x.interpolate(x => x),
        }}
      >
        {children}
      </animated.main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
