import React, { useState } from "react"
import { Link } from "gatsby"
import BraveBurger from "./braveBurger"
import Nav from "./nav"
import Logo from "../../svg/logo.svg"

const Header = ({ reverseHeader }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <header className="flex items-center justify-between w-100 top-0 left-0 absolute ph3 ph5-l pv4 z-max">
        <Link to="/" className="logo">
          <Logo
            className={`${
              isOpen
                ? "fill-black"
                : reverseHeader
                ? "fill-white"
                : "fill-black"
            }`}
          />
        </Link>
        <div onClick={() => setOpen(!isOpen)}>
          <BraveBurger menuState={isOpen} reverseHeader={reverseHeader} />
        </div>
      </header>
      <Nav menuState={isOpen} closeNav={() => setOpen(false)} />
    </>
  )
}

export default Header
